import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const IndexPage = () => {
    return (
        <Layout pageTitle="Partners - Press Releases &amp; Emails">
            <Row>
                <Col>
                    <Link to="/partners">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}>
                    <h1>Solus Emails</h1>
                </Col>
            </Row>
            <ValueRow
                name="Partnership Accouncement"
                href="https://docs.google.com/document/d/1m5a_xnEs7XOUzg6vqUIL9oS9BZ2jK4ch6Wbb2ua1OzE/edit?usp=sharing"
            />
             <ValueRow
                name="Awards Introduction"
                href="https://docs.google.com/document/d/1qmrQJmPGrrHZh6S0RDQNHuR1a1G-OvatbOM-63b-eno/edit?usp=sharing"
            />
             <ValueRow
                name=" Awards General Promo"
                href="https://docs.google.com/document/d/10puqRIqzuL1TfqG1yAitHJGZhCpBrmJrJVIRgRuz6AQ/edit?usp=sharing"
            />
             <ValueRow
                name=" Quote from sponsor"
                href="https://docs.google.com/document/d/1LbIyC0khH1OQy4Yprc1xuQFOmxldiwrANp8RwwtlveU/edit?usp=sharing"
            />
<br></br><br></br><br></br>
<Row className="mt-8">
                
                    <h1>Press Releases</h1>
                
            </Row>

            <ValueRow
                name="Get Pitch Perfect Partnership"
                href="https://docs.google.com/document/d/1j6up7g7J4EVNVu8a44uQSfnQCtR0ln9-EVntCWA1qQc/edit?usp=sharing"
            />
          
        </Layout>
    )
}

const ValueRow = ({ name, href }) => {
    return (
        <Row className="mt-5">
            <Col>
                <h3>{name}</h3>
                <a
                    href={href}
                    className="btn btn-primary m-3"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Download Document
                </a>
            </Col>
        </Row>
    )
}

export default IndexPage
